import React, { Component } from 'react';
import { FcBusinesswoman } from 'react-icons/fc';
import { FcTimeline } from 'react-icons/fc';
import { FcServices } from 'react-icons/fc';
import { FcCurrencyExchange } from 'react-icons/fc';

let userLoggedIn;
class AccountInfo extends Component {

    constructor(props) {
        super(props)
        userLoggedIn = JSON.parse(localStorage.getItem('CURRENT_USER'));
      }
    render() {
        // const { data } = this.props;
        return (
            <div className="accountinfo" style={this.props.loading ? {opacity: .2} : {}}>

                <div className="accountinfo__balance">
                    <div className="accountinfo__balance-box">
                        <span className="accountinfo__balance-text">total Balance</span>
                        <span className="accountinfo__balance-value">{`$${userLoggedIn.data.Balance}`}</span>
                    </div>
                    <div className='accountinfo__pending'>
                    <h4 className="accountinfo__balance-header">Important Information</h4>
                    <p className="accountinfo__balance-description">Hello <span>Client</span>, This balance reflects the <span>current balance </span>
                    considering all successful transactions. All Pending transactions are not deducted from the balance  information and are only
                    considered once <span>successful</span>. Contact us to review your pending transactions.
                    </p>
                    <div className="accountinfo__balance-pending">0 Pending Transactions</div>
                    </div>
                    
                </div>

                <div className="accountinfo__details">
                    <div className="accountinfo__card accountinfo__card--1">
                        <div className="accountinfo__card-titlewrap">
                            <FcBusinesswoman className="accountinfo__card-icon" />
                            <p className="accountinfo__card-title">Name</p>
                        </div>
                        <p className="accountinfo__card-text">{userLoggedIn.data.Fullname}</p>
                    </div>

                    <div className="accountinfo__card accountinfo__card--2">
                        <div className="accountinfo__card-titlewrap">
                            <FcTimeline className="accountinfo__card-icon" />
                            <p className="accountinfo__card-title">Account ID</p>
                        </div>
                        <p className="accountinfo__card-text">{userLoggedIn.data.Accountnumber}</p>
                    </div>

                    <div className="accountinfo__card accountinfo__card--3">
                        <div className="accountinfo__card-titlewrap">
                            <FcServices className="accountinfo__card-icon" />
                            <p className="accountinfo__card-title">Account Type</p>
                        </div>
                        <p className="accountinfo__card-text">Offshore Account</p>
                    </div>

                    <div className="accountinfo__card accountinfo__card--4">
                        <div className="accountinfo__card-titlewrap">
                            <FcCurrencyExchange className="accountinfo__card-icon" />
                            <p className="accountinfo__card-title">Currency</p>
                        </div>
                        <p className="accountinfo__card-text">Dollars</p>
                    </div>

                </div>

            </div>
        );
    }
}

export default AccountInfo;