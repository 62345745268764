import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import { FaMagic } from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Directors extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Who are We</h2>
                    <div className='personal__subhead'> Who are We || Board Of Directors</div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Board Of Directors</h2>
                    <p className='personal__description'>A board of directors (B of D) is an elected group of individuals that represent shareholders. The board is a governing body that typically meets at regular intervals to set corporate management and oversight policies. Every public company must have a board of directors. Some private and nonprofit organizations also have a board of directors. This also applies to German GMBH companies.</p>
                </div>

               
                <div className='personal__body personal__body--wide'>
                

                <div class="row">
                    <div class="col-1-of-3">
                       <div class="card">
                           <div class="card__side card__side--front">
                                <div class="card__picture card__picture--1">
                                    &nbsp;
                                </div>
                                <h4 class="card__heading">
                                    <span class="card__heading-span card__heading-span--1">The Sea Explorer</span>
                                </h4>
                                <div class="card__details">
                                    <ul>
                                        <li>Daniel Augustin</li>
                                        <li>Chairman</li>
                                        <li>20 years expereience</li>
                                        <li>Chartered Accountant</li>
                                        <li>42 Years</li>
                                    </ul>
                                </div>
                           </div>
                           
                       </div>
                    </div>


                    <div class="col-1-of-3">
                        <div class="card">
                            <div class="card__side card__side--front">
                                <div class="card__picture card__picture--2">
                                    &nbsp;
                                </div>
                                <h4 class="card__heading">
                                    <span class="card__heading-span card__heading-span--2">The Forest Hiker</span>
                                </h4>
                                <div class="card__details">
                                    <ul>
                                        <li>Sam Stenbergg</li>
                                        <li>Managing Director</li>
                                        <li>22 Years expereience</li>
                                        <li>Chartered Accountant</li>
                                        <li>55 Years</li>
                                    </ul>
                                </div>

                            </div>
                            
                        </div>
                    </div>


                    <div class="col-1-of-3">
                        <div class="card">
                            <div class="card__side card__side--front">
                                <div class="card__picture card__picture--3">
                                    &nbsp;
                                </div>
                                <h4 class="card__heading">
                                    <span class="card__heading-span card__heading-span--3">The Snow Adventurer</span>
                                </h4>
                                <div class="card__details">
                                    <ul>
                                        <li>Hosni Dalefield</li>
                                        <li>Board Member</li>
                                        <li>6 Years expereience</li>
                                        <li>Chartered Accountant, Lawyer</li>
                                        <li>34 Years</li>
                                    </ul>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>

                
            


                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Directors;
