import React, { Component } from "react";

let data;

class Transactions extends Component {

    

    constructor(props) {
        super(props);
        this.NoServiceModal = React.createRef();
        

    }

    componentDidMount() {

        //  this.loadUserData();
        // console.log('Transaction data is '+ this.props.data)
       
    }


    loadUserData() {
        data = localStorage.getItem("user");
        data = JSON.parse(data);
        // console.log(data);
        this.setState({
            pending: data.Pendingtransactions,
        })
    }

    saveUserDetailsToLocalStorage(data) {
        this.setState({
            pending: data.Pendingtransactions,
        })

        data = JSON.stringify(data)
        localStorage.setItem("user", data);
    }


    render() {
        const { data } = this.props;
        
        
        return (
            <div className="transaction__container">
                <div className="transaction__history">
                    <h2 className="heading-secondary">Transaction History &#8595;</h2>
                    <div className="history__container">

                        <table>
                            <caption className="statement__header">Statement Summary</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>




                            <tbody>
                                {
                                    /*
                                    <tr>
                                    <td scope="row" data-label="Type">DEPOSIT</td>
                                    <td data-label="Description">Initial Bank Deposit, Main Branch, Adum</td>
                                    <td data-label="Amount">$5,000,000.00</td>
                                    <td data-label="Date">25/08/2021</td>
                                    <td data-label="Status">SUCCESS &#10004;</td>
                                </tr>
                            
                                <tr>
                                    <td scope="row" data-label="Type">DEPOSIT</td>
                                    <td data-label="Description">Bank Deposit, Main Branch, Adum</td>
                                    <td data-label="Amount">$5,000,000.00</td>
                                    <td data-label="Date">07/09/2021</td>
                                    <td data-label="Status">SUCCESS &#10004;</td>
                                </tr>
                                    */
                                }

                                {this.props.amounts.map((x, i) => 
                                    <tr>
                                        <td scope="row" data-label="Type">DEPOSIT</td>
                                        <td data-label="Description">Mobile Deposit</td>
                                        <td data-label="Amount">{`$${x}`}</td>
                                        <td data-label="Date">{this.props.dates[i]}</td>
                                        <td data-label="Status">SUCCESS &#10004;</td>
                                    </tr>
                                )


                                }


                            </tbody>



                        </table>
                    </div>
                </div>
            </div>
        );

    }
}

export default Transactions;