import './App.scss';
import React, { Component } from 'react';
import {Landing} from './features/Landing';
import {Home} from './features/Home';
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import { Personal, Saving, Premium, Loan, Kid, Business, Insurance, Investments, Corporate, CorporateIntro, Mission, Vision, Overview, Directors } from './features/Tabs'
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore"

// import emailjs from 'emailjs-com';

const firebaseConfig = {
  apiKey: "AIzaSyCHJVZuMlhqp2OWKd40emBoIIM7-Fswim8",
  authDomain: "gilbratar-6ac92.firebaseapp.com",
  projectId: "gilbratar-6ac92",
  storageBucket: "gilbratar-6ac92.appspot.com",
  messagingSenderId: "859034479330",
  appId: "1:859034479330:web:6367754cc4cd1000188cbb",
  measurementId: "G-5TMMLLZVZH"
};

let APPDATA = [];

class App extends Component {

  /*
  constructor(props) {
    super(props)
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      firebase.firestore().collection('users').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            users.push(doc.data())
        });
        localStorage.setItem('USERS', JSON.stringify(users))
    }).catch((error) => {
        console.log(error)
    }) 
   }else {
      firebase.app(); // if already initialized, use that one
   }
    emailjs.init('user_OvEa2qrB4e1QuIhR9fwDE');
  }
  */

  constructor(props) {
    super(props)
    const c = initializeApp(firebaseConfig);
    // let mydata = ['1','4',5]
    // mydata = JSON.stringify(mydata);
    // console.log(mydata)
  }

  componentDidMount() {

    
    // firebaseApp();
    this.fetchData()
    // console.log(this.props.navigate)
}

fetchData = async () => {
    const db = getFirestore();
    console.log(db)
    const querySnapshot = await getDocs(collection(db, "user"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        APPDATA.push({ id: doc.id, data: doc.data() })
    });
    // console.log(APPDATA)
    localStorage.setItem('DATA', JSON.stringify(APPDATA))
}

  render() {
    return (
      <Router>
        <Routes>
          <Route exact  path="/" element={<Landing />} />
          <Route  path="/home" element={<Home />} />
          <Route  path="/current" element={<Personal />} />
          <Route  path="/premium" element={<Premium />} />
          <Route  path="/loan" element={<Loan />} />
          <Route  path="/saving" element={<Saving />} />
          <Route  path="/kid" element={<Kid />} />
          <Route  path="/insurance" element={<Insurance />} />
          <Route  path="/investment" element={<Investments />} />
          <Route  path="/business" element={<Business />} />
          <Route  path="/corporateintro" element={<CorporateIntro />} />
          <Route  path="/corporate" element={<Corporate />} />
          <Route  path="/overview" element={<Overview />} />
          <Route  path="/mission" element={<Mission />} />
          <Route  path="/vision" element={<Vision />} />
          <Route  path="/directors" element={<Directors />} />
          
        </Routes>
      </Router>
      
    );
  }
  
}

export default App;
