import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Kid extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Personal Account</h2>
                    <div className='personal__subhead'> Personal Account || Kid Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Kid Account</h2>
                    <p className='personal__description'>Giving your child the right jump-start in life could never be earlier. Inculcating in your children how to manage their money from a young age will benefit not only them as they grow but will also help them to be financially sound in their adult life.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Details</h5>
                        <p>AllyStanchart Kid Account is a savings account designed for children up to 18 years with no monthly fees but a host of added benefits. It gives trustees the opportunity for parents or guardians to build up funds to support their wards’ life needs. In a situation of death, incapacitation and unemployment of the Trustee, the child/ward of can still acquire some Education or Trade with the savings in the account.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Kid
