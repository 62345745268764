import React, { Component } from 'react';
import Logo from '../../img/logo.png';

import Client from '../../img/user.png';

import { RiLogoutBoxRFill } from 'react-icons/ri';
import AccountInfo from './AccountInfo';
import QuickTransfer from './QuickTransfer';
import Transactions from './Transactions';
import {Footer} from '../../_shared';
import ClipLoader from "react-spinners/ClipLoader";
import disableScroll from 'disable-scroll';

let userLoggedIn;
class Home extends Component {

  state = { 
    windowWidth: window.innerWidth,
      showSpinner: false,
      isDisconnected: false,

      data: {},
  };

  constructor(props) {
    super(props)
    userLoggedIn = JSON.parse(localStorage.getItem('CURRENT_USER'));
  }

      
      handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
       };
       /*
      
       componentDidMount() {
        userLoggedIn = localStorage.getItem("loginState");
        console.log(userLoggedIn);
        if (userLoggedIn === "false") {
            this.props.history.replace("/");
            console.log('Redirected  to login');
        }
        window.addEventListener("resize", this.handleResize);
        // this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
       }
      
       componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
       }
       */

       /*
       handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
          const webPing = setInterval(
            () => {
              fetch('//google.com', {
                mode: 'no-cors',
                })
              .then(() => {
                this.setState({ isDisconnected: false }, () => {
                  return clearInterval(webPing)
                });
              }).catch(() => this.setState({ isDisconnected: true }) )
            }, 2000);
          return;
        }
  
        return this.setState({ isDisconnected: true });
      }
      */

    render() {
      // console.log(this.props.location.state.detail)
      // const { detail } = this.props.location.state 
        return(
            <div className="home">
                <ClipLoader loading={this.state.showSpinner} size={this.state.windowWidth * .1} />
                <div className="home__header" style={this.state.showSpinner ? {opacity: .2} : {}}>
                    <div className="home__header__logobox">
                        <img src={Logo} alt="Logo of the Company" className="home__header__logo" />
                    </div>
                    <span className="home__header__welcome">WELCOME to AllyStancharts bank</span>
                    <div className="home__header__client">
                        <div className="home__header__client-imgbox">
                        <img 
                          src= {Client} 
                          alt="Passport of client" 
                          className="home__header__client-img" 
                        />
                        </div>
                        <span className="home__header__client-name">{userLoggedIn.data.Username}</span>
                    </div>
                    <div className="home__header__logout" onClick={() => this.handleLogout()}>
                        <RiLogoutBoxRFill className="home__header__logout-icon"/>
                        <p className="home__header__logout-text">Logout</p>
                    </div>
                </div>

                <AccountInfo loading={this.state.showSpinner} />

                <QuickTransfer loading={this.state.showSpinner}/>


               <Transactions data={userLoggedIn.data} amounts={JSON.parse(userLoggedIn.data.Amount)} dates={JSON.parse(userLoggedIn.data.Date)}/>
                

                <Footer />

            </div>
        );
    }

    handleLogout = () => {
        this.setState({showSpinner: true});

        /*
        if (!this.state.isDisconnected) {
            disableScroll.on();

            localStorage.setItem('loginState', 'false');

            setTimeout(() => this.setLogoutStatus(), 3000)
        } else {
            setTimeout(() => this.setState({showSpinner: false}), 3000);
        }
        
        */

        
        
       this.setLogoutStatus();
        
    }

    setLogoutStatus = () => {
        // this.setState({showSpinner: false});
        disableScroll.off();
        this.props.navigate('/')
    }
}

export default Home;