import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Saving extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Personal Account</h2>
                    <div className='personal__subhead'> Personal Account || Savings Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Savings Account</h2>
                    <p className='personal__description'>This account is available to individuals who have attained 18-years and above and offers a secure and attractive method of saving funds at a competitive rate. Withdrawals can be done three times in a month.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Requirement</h5>
                        <p>The basic requirements for account opening are; An identification; this includes one (1) passport sized picture and a valid National ID (acceptable options; Voter ID, National ID, Driver’s Licence and International Passport) Proof of address; completion of address verification form. Applications are subject to status and internal checks.</p>
                    </div>

                    <div className='personal__body__item'>
                        <h5>Features</h5>
                        <p>Competitive interest rate.
                            Electronic banking services such as SMS alert and internet banking are available on this account including VISA, MasterCard and Red card.
                            Cheque book issuance.</p>
                    </div>

                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Saving
