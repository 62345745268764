import React, { Component } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

export class Navigator extends Component {

    state = {

        personal: false,
        business: false,
        corporate: false,
        whoarewe: false,
        internetBanking: false,

    };

    


    render() {

        const { personal, business, corporate, whoarewe, internetBanking } = this.state;
        return (
            <div class="navigation">
                    <input type="checkbox" class="navigation__checkbox" id="navi-toggle" />

                    <label for="navi-toggle" class="navigation__button">
                        <span class="navigation__icon">&nbsp;</span>
                    </label>

                    <div class="navigation__background">&nbsp;</div>

                    <nav class="navigation__nav">
                        <ul class="navigation__list">
                            <li class="navigation__item"><a href="#" class="navigation__link"><Link to="/" style={styles.menulink}>Home</Link> </a></li>
                            <li class="navigation__item">
                                <a href="#" class="navigation__link" 
                                    onClick={() => this.setState({ personal: !personal, whoarewe: false, internetBanking: false, business: false,  corporate: false})}
                                >
                                    Personal
                                    <IoIosArrowForward className="navigation__link__icon" />

                                </a>
                                <ul
                                    className='navigation__submenu'
                                    style={personal ? {} : { display: 'none', width: '0' }}>
                                    <li><Link to='/current' style={styles.sublink} className='navigation__submenu__item'>Current Account</Link></li>
                                    <li><Link to='/saving' style={styles.sublink} className='navigation__submenu__item'>Saving Account</Link></li>
                                    <li><Link to='/premium' style={styles.sublink} className='navigation__submenu__item'>Premium Account</Link></li>
                                    <li><Link to='/kid' style={styles.sublink} className='navigation__submenu__item'>Kid Account</Link></li>
                                    <li><Link to='/loan' style={styles.sublink} className='navigation__submenu__item'>Loan Account</Link></li>
                                </ul>
                            </li>
                            <li class="navigation__item">
                                <a href="#" class="navigation__link"
                                    onClick={() => this.setState({ personal: false, whoarewe: false, internetBanking: false, business: !business,  corporate: false})}
                                >
                                    Business
                                    <IoIosArrowForward className="navigation__link__icon" />
                                </a>
                                <ul
                                    style={business ? {} : { display: 'none', width: '0' }}
                                    className='navigation__submenu'
                                >
                                    <li><Link to='/business' style={styles.sublink} className='navigation__submenu__item'>Business Account</Link></li>
                                    <li><Link to='/insurance' style={styles.sublink} className='navigation__submenu__item'>Insurance</Link></li>
                                    <li><Link to='/investment' style={styles.sublink} className='navigation__submenu__item'>Investment</Link></li>
                                </ul>
                            </li>
                            <li class="navigation__item">
                                <a href="#" className='navigation__link'
                                    onClick={() => this.setState({ personal: false, whoarewe: false, internetBanking: false, business: false,  corporate: !corporate})}
                                >
                                    Corporate
                                    <IoIosArrowForward className="navigation__link__icon" />
                                </a>
                                <ul 
                                    className='navigation__submenu'
                                    style={corporate ? {} : { display: 'none', width: '0' }}
                                >
                                    <li><Link to='/corporateintro' style={styles.sublink} className='navigation__submenu__item'>Overview</Link></li>
                                    <li><Link to='/corporate' style={styles.sublink} className='navigation__submenu__item'>Corporate Account</Link></li>
                                </ul>

                            </li>
                            <li class="navigation__item">
                                <a href="#" className='navigation__link'
                                    onClick={() => this.setState({ personal: false, whoarewe: !whoarewe, internetBanking: false, business: false,  corporate: false})}
                                >
                                    Who we are  <IoIosArrowForward className="navigation__link__icon" />
                            </a>

                                <ul 
                                    className='navigation__submenu'
                                    style={whoarewe ? {} : { display: 'none', width: '0' }}
                                >
                                    <li><Link to='/overview' style={styles.sublink} className='navigation__submenu__item'>Overview</Link></li>
                                    <li><Link to='/mission' style={styles.sublink} className='navigation__submenu__item'>Our Mission</Link></li>
                                    <li><Link to='/vision' style={styles.sublink} className='navigation__submenu__item'>Our Value</Link></li>
                                    <li><Link to='/directors' style={styles.sublink} className='navigation__submenu__item'>Board of Directors</Link></li>
                                    <li className='navigation__submenu__item'>Frequently Asked Questions</li>
                                </ul>


                            </li>
                            <li class="navigation__item"><a href="mailto: support@AllyStanchart.com"  className='navigation__link'>Contact</a></li>
                            <li class="navigation__item">
                                <a href="#" className='navigation__link'
                                    onClick={() => this.setState({ personal:false, whoarewe: false, internetBanking: !internetBanking, business: false,  corporate: false})}
                                >
                                Internet Banking
                                <IoIosArrowForward className="navigation__link__icon" />
                            </a>
                                <ul 
                                    className='navigation__submenu'
                                    style={internetBanking ? {} : { display: 'none', width: '0' }}
                                >
                                    <li className='navigation__submenu__item'>Login</li>
                                    <li className='navigation__submenu__item'>Open Account</li>

                                </ul>
                            </li>

                        </ul>
                    </nav>
                </div>

        )
    }
}

export default Navigator

const styles = {
    menulink: {
        textDecoration: 'none',
        color: 'currentColor',

    },



    sublink: {
        textDecoration: 'none',
        color: 'currentColor',
    }
}