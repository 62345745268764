import React, { Component } from 'react';
import { Element,  } from 'react-scroll';

class NewsLetter extends Component {
    render() {
        return (
            <Element name="subscribe" className="newsletter">
                <section>
                <div className="newsletter__header-container">
                    <h2 class="newsletter__header">&#9824; Signup for News Letter</h2>
                </div>
                
                <div className="newsletter__container">
                    <div class="newsletter__form__group">
                        <input type="email" class="newsletter__form__input" placeholder="Email address" id="email" required />
                        <label for="email" class="newsletter__form__label">Email address</label>
                    </div>

                    <button className="btn newsletter__sub-btn" onClick={() => this.reloadPage()}>Subscribe</button>
                </div>
                </section>
            </Element>
        );
    }

    reloadPage = () => {
        setTimeout(()=> {
            window.location.reload();
        }, 1000)
    }
}

export default NewsLetter;