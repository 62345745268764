import React, { Component } from 'react';
import { HiLightBulb } from "react-icons/hi";
import { GiTargetArrows } from "react-icons/gi";
import AboutUs1 from '../../img/about3.jpg';
import AboutUs2 from '../../img/about2.jpg';

class AboutUs extends Component{
    render() {
        return(
            <section className="aboutus">
                <figure className="aboutus__img1box">
                    <img src={AboutUs2} className="aboutus__img1" alt="About Us Images"/>
                </figure>

                <figure className="aboutus__img2box">
                <img src={AboutUs1} className="aboutus__img2" alt="About Us Images"/>
                </figure>

                <div className="aboutus__branches">43 <span>Branches</span></div>

                <div className="aboutus__awards">16 <span>Awards</span></div>

                <div className="aboutus__textbox">
                    <h2 className="aboutus__header">&#9824; About Us</h2>

                    <p className="aboutus__subheader">Not fast decision but good <span>decision</span> fast</p>

                    <div className="aboutus__goal">
                        <div className="aboutus__goal__headerbox">
                            <GiTargetArrows className="aboutus__goal__icon"/>
                            <h3 className="aboutus__goal__header">Our Goals</h3>
                        </div>
                        <p className="aboutus__goal__text">Building and developing a professional banking services team, with the best experience
                            and the ability to implement the latest techniques and systems
                        </p>
                    </div>

                    <div className="aboutus__vision">
                        <div className="aboutus__vision__headerbox">
                            <HiLightBulb className="aboutus__vision__icon"/>
                            <h3 className="aboutus__vision__header">Our Vision</h3>
                        </div>
                        <p className="aboutus__vision__text">To be the clients’ best choice through offering an integrated and
                         distinctive bundle of banking services
                        </p>
                    </div>
                    <button className="btn aboutus--btn">Welcome again</button>
                </div>
            </section>
        );
    }
}

export default AboutUs