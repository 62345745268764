import React, { Component } from "react";
import { RiExchangeDollarLine } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";
import { MdPhonelinkRing } from "react-icons/md";

class PinnedCards extends Component {
    render() {
        return (
            <section className="pinned-actions pinned-actions__container">
                <div class="pinned-actions__card">
                    <RiExchangeDollarLine color="#111" className="pinned-actions__icon"/>
                    <p className="pinned-actions__title">money transfer</p>
                </div>

                <div class="pinned-actions__card">
                    <GiReceiveMoney color="#111" className="pinned-actions__icon"/>
                    <p className="pinned-actions__title">withdraw</p>
                </div>

                <div class="pinned-actions__card">
                    <GiPayMoney color="#111" className="pinned-actions__icon"/>
                    <p className="pinned-actions__title">bank deposit</p>
                </div>

                <div class="pinned-actions__card">
                    <MdPhonelinkRing color="#111" className="pinned-actions__icon"/>
                    <p className="pinned-actions__title">online payment</p>
                </div>
            </section>
        );
    }
}
export default PinnedCards;
