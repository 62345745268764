import React, { Component } from "react";

import ImageSlide from './ImageSlide';
import PinnedCards from './PinnedCards';
import AboutUs from './AboutUs';
// import Login from './Login';
// import Branches from "./Branches";
import Services from './Services';
import NewsLetter from './NewsLetter';
import { Footer, Dropdown, Header, Navigator } from '../../_shared/index';
// import { css } from "@emotion/core";
import { css } from '@emotion/react'
import RingLoader from "react-spinners/RingLoader";
import disableScroll from 'disable-scroll';
import { Element } from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

// import firebase from 'firebase';


// include styles for rodal

import 'rodal/lib/rodal.css';

let user = []

let APPDATA = [];

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  
`;


class Landing extends Component {

    constructor(props) {
        super(props);
        APPDATA = localStorage.getItem('DATA');

        // console.log(JSON.parse(APPDATA))
        // console.log(this.props.location)
    }

    state = {
        windowWidth: window.innerWidth,
        showLoader: false,
        isDisconnected: false,
        loginError: false,

        personal: false,
        business: false,
        corporate: false,
        whoarewe: false,
        internetBanking: false,

        offSet: 0,

    };

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        const data = this.props.location.state;
       
        setTimeout(() => {
            if (data.goToLogin) {
                // console.log('Jeyyy')
                // console.log(this.state.offSet)
                scroll.scrollTo(document.getElementById('login-section').getBoundingClientRect().top)
            }
             
        }, 2000);
        
        

        // this.handleConnectionChange();
        // window.addEventListener('online', this.handleConnectionChange);
        // window.addEventListener('offline', this.handleConnectionChange);


    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
        // window.removeEventListener('online', this.handleConnectionChange);
        // window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            this.setState({ isDisconnected: false }, () => {
                                return clearInterval(webPing)
                            });
                        }).catch(() => this.setState({ isDisconnected: true }))
                }, 2000);
            return;
        }

        return this.setState({ isDisconnected: true });
    }

    hideMenu() {
        this.setState({
            menuVisible: false,
        })
    }

    render() {

        return (
            <div className="landing__container" >

                <Header />
                {/*
                    <header className="header" >
                    <nav>
                        <img src={logo} alt="Loans International Logo" className="header__logo"/>
                        <ul className="header__list">
                            <li><a className="header__item"><Link>Home</Link></a></li>
                            <li><a className="header__item"><Link to="services" smooth={true}>Services</Link></a></li>
                            <li><a className="header__item"><Link to="subscribe" smooth={true}>Subscribe</Link></a></li>
                            <li><a className="header__item"><Link to="footer" smooth={true}>Contact Us</Link></a></li>
                        </ul>
                        <button className="btn btn--login"><Link to="login" smooth> Login </Link></button>
                    </nav>
                </header>
                */}

                <Dropdown location={this.props.location} />

                <section className="image-slide">
                    <ImageSlide />
                </section>


                <PinnedCards />

                <AboutUs />

                <Services />

                <form class="section-book" onSubmit={(e) => this.handleSubmit(e)} id="login-section">
                    <RingLoader css={override} loading={this.state.showLoader} size={this.state.windowWidth * .32} color="#ff355e" />
                    <Element name="login-section" class="row" style={this.state.showLoader ? { display: "none" } : {}}>
                        <div class="book">
                            <div class="book__form">
                                <div class="form">
                                    <div>
                                        <h2 class="heading-secondary" style={this.state.loginError ? { color: '#ff4500' } : {}}>
                                            {this.state.loginError ? 'LOGIN FAILED' : 'LOGIN TO YOUR ACCOUNT'}
                                        </h2>
                                    </div>

                                    <div class="form__group">
                                        <input type="text" class="form__input" placeholder="Username" id="name" required />
                                        <label for="name" class="form__label">Account Number</label>
                                    </div>

                                    <div class="form__group">
                                        <input type="password" class="form__input" placeholder="Password" id="pword" required />
                                        <label for="pword" class="form__label">Password</label>
                                    </div>


                                    <div class="form__group">
                                        <button class="btn">Login &rarr;</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Element>
                </form>




                <NewsLetter />

                <Footer />
                <Navigator />

            </div>
        );
    }

    handleSubmit(event) {

        event.preventDefault();

        this.setState({ showLoader: true });
        disableScroll.on();

        // const userName = 'Shakespeare';
        // const password = 'Happyendings';
        let enteredUsername = event.target[0].value;
        let enteredPassword = event.target[1].value;

        enteredUsername = enteredUsername.trim();
        enteredPassword = enteredPassword.trim();
        // console.log(APPDATA)
        const user = JSON.parse(APPDATA).filter(x => x.data.Username === enteredUsername && x.data.Password === enteredPassword)
        // console.log(user)
        if (user.length !== 0) {
            // console.log(user)
            localStorage.setItem('CURRENT_USER', JSON.stringify(user[0]))
            this.props.navigate('/home')
        } else {
            this.setState({ loginError: true })
            alert('Login Failed')
        }

        this.setState({ showLoader: false });
        disableScroll.off();


        /*
        if (userName === enteredAccountNumber && password === enteredPassword) {
            localStorage.setItem("loginState", "true");
            //  console.log('here');

            setTimeout(() => this.setLoginStatus(), 3000);
            
        } else {
            setTimeout(() => this.setState({showLoader: false, loginError: true}), 3000);
           
            disableScroll.off();
        }
        */

        /*
        this.setState({ spinnerState: true });

        const loginEmail = "admin123@gmail.com";

        const loginPword = "test123";

        const userEmail = "fredfrimps12@gmail.com";

        const userPassword = "5mrvpf1";

        let userName = event.target[0].value;
        let userPword = event.target[1].value;
        console.log(userName);
        console.log(userPword)

        firebase.auth().signInWithEmailAndPassword(loginEmail, loginPword).then(() => {
            console.log("here start");
            const db = app.firestore();
            const usersRef = db.collection('users');
            usersRef
                .where("Username", "==", userName)
                .where("password", "==", userPword)
                .get().then((querySnapshot) => {
                    console.log(querySnapshot.docs);
                    if (!querySnapshot.docs.length) {
                        console.log("We here baby")
                        
                        this.setState({
                            spinnerState: false,
                        });
                        alert("Wrong UserName or Password");
                       
                    } else{
                        querySnapshot.forEach((document) => {
                       
                            console.log('Gotcha user');
                            console.log(document.data());
                            this.saveUserDetailsToLocalStorage(document.data());
                            this.setLoginStatus(true);
                        
                    });
                    }
                   

                }).catch((error) => {
                    console.log("We never get here")
                   
                });


        }).catch((error) => {
            //  Handle Errors here.
            console.log(error.code);
            console.log(error.message);

        });
        */
    }

    /*

    fetchUser(name,pword) {
        user = [];
        firebase.firestore().collection('users').where('username', '==', name).where('password', '==', pword).get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                user.push(doc.data())
            });
            if (user.length > 0) {
                localStorage.setItem("loginState", "true");
                //  console.log('here');

                setTimeout(() => this.setLoginStatus(), 3000);
                
            } else {
                setTimeout(() => this.setState({showLoader: false, loginError: true}), 3000);
               
                disableScroll.off();
            }
        }).catch((error) => {
            setTimeout(() => this.setState({showLoader: false, loginError: true}), 3000);
            console.log(error)
        })
        
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    
    }
    */

    setLoginStatus = () => {
        this.setState({ showLoader: false });
        disableScroll.off();
        this.props.history.push({
            pathname: '/home',
            state: { detail: user[0] }
        });
    }

}
export default Landing;


