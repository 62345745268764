import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Mission extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Who are We</h2>
                    <div className='personal__subhead'> About || Our Mission </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Mission</h2>
                    <p className='personal__description'> AllyStanchart International is a locally loaned community bank that provides a variety of core financial services to businesses, professionals, and individuals. We promise our customers immediate, direct access to our bank decision makers and deliver the finest personalized service in the industry. Centric has committed people and resources to enrich the communities where we live and work. Because trust is our most important commodity, we are focused on building and sustaining long-term generational relationships with our customers, our community, our employees, and our shareholders. In every transaction, We Revolve Around You.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>AllyStancharte So Far</h5>
                        <p>AllyStanchart International Bank contributes to the sustainable development of Azerbaijan by providing responsible financial services and solutions to households and micro, small and medium enterprises, using internationally recognized best banking practices. We are committed to delivering value for our clients, shareholders, employees, and society at large. The mission is based on our values: integrity and openness, professionalism, commitment to customers, team work, and social and environmental responsibility.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Mission