import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Insurance extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Business Account</h2>
                    <div className='personal__subhead'> Business || Insurance Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Insurance Account</h2>
                    <p className='personal__description'>An electronic insurance account or an e-insurance account (eIA) is the portfolio of insurance policies for a person held in an electronic form with an insurance repository. It is aimed at helping eIA holders to keep a track of insurance policies, life as well as non-life, across multiple insurers at one place. 
                    Like a demat account that facilitates direct equity holdings at one place, an eIA holds all your policies in one place and is accessible to you anytime, anywhere. </p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Long-Term Care Insurance</h5>
                        <p>Protect your family from the rising cost of long-term medical care. Long-term care includes a broad range of medical and personal services designed to assist individuals with common daily activities. These services normally are needed as a result of physical and/or cognitive impairments common with the aging process. The need for long-term care can occur at any age due to accidents or prolonged illness. Your long-term care can include a broad range of medical and personal services designed to assist individuals with common daily activities.</p>
                    </div>
                    
                </div>

                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Importers & Exporters Insurance</h5>
                        <p>The AllyStanchart Bank UK Importers & Exporters Insurance (IMEX) offers protection of imports or exports against unforeseen risks. This policy also guarantees the safety of the goods in transit until they reach their intended destination. The policy covers theft, fire and jettison (when goods are thrown away).</p>
                    </div>
                    
                </div>

                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Motor Insurance</h5>
                        <p>AllyStanchart Motor Insurance is offered to ensure that the Customer motoring public is offered an accessible protection to mitigate their liability to third parties against unforeseen risks.</p>
                    </div>
                    
                </div>

                

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Insurance
