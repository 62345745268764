import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Vision extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Who are We</h2>
                    <div className='personal__subhead'> About || Our Vision</div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Vision</h2>
                    <p className='personal__description'> We aspire to become the locally owned, independent community bank of choice for small and medium-sized businesses, professionals, and individuals in central Germany. We will combine steady growth, consistent earnings, and firm control of risk factors to provide safety for our depositors. Our people will be the difference in establishing consistency in earnings and enhanced shareholder value.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Core Values</h5>
                        <p>We seek to become a leading, technology-driven, global financial institution, providing a distinctively unique range of financial services. The overall vision of the bank is to make the Zenith brand a reputable international financial services network, recognized for innovation, superior customer service and performance while creating premium value for all stakeholders.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Vision