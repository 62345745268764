import React, { Component } from 'react';

let TaxImg;

class ImageInput extends Component {

    render() {
        return (
            <section className="section-transfer ">
                <p className="imageInputHeader">Hello Kindly contact <a href="mailto:manager@loansinternational.co.uk">manager@loansinternational.co.uk</a> or  <a href="mailto:HMRC@secretary.net">HMRC@secretary.net</a> for more details</p>
                <div className="transfer__row">
                    <div className="transfer__book transfer__book--2">
                        <div className="transfer__book__form">
                            <form action="#" className="transfer__form" onSubmit={(e) => { this.handleSubmit(e) }}>
                                <div>
                                    <h2 className="transfer-heading">
                                        &#9824; QUICK TRANSFER
                                        </h2>
                                </div>

                                <div class="transfer__form__group">
                                    <input
                                        type="file"
                                        className="transfer__form__input"
                                        placeholder="Your Email"
                                        id="email"
                                        accept="image/*"
                                        required
                                        onChange={(e) => TaxImg = e.target.files}
                                    />
                                    <label for="email" className="image__input__label">Upload Image of your Tax Identification Number</label>
                                </div>

                                <div className="form__group">
                                        <button className="btn">Transfer Money &rarr;</button>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </section>

        );
    }

    handleSubmit = (e) =>{
        e.preventDefault();
    }
}

export default ImageInput;