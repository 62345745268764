import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Business extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Business Account</h2>
                    <div className='personal__subhead'> Business || Business Current Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Business Account</h2>
                    <p className='personal__description'>Our Business Current Account allows you to make deposits, withdrawals and payments to third parties, as well as receive payments from your customers through various channels. The account enables you to keep accurate banking records, produces statements for easy reference, and helps you to build a banking relationship and credit risk profile. A Business Current Account is simple to manage and lets you transact in the way that is most convenient for you, from traditional branch banking to our advanced electronic services.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Benefits</h5>
                        <p>No minimum balance required
                            Available in major international currencies British pounds, euro, South African rand and Chinese yuan
                            Unlimited number of transactions
                            100%-page chequebook
                            Free SMS or email alerts
                            Visa Business Debit card  up to four cards can be issued for each business. Daily cash withdrawal limit of up to $ 3 000.00 can be set for each card
                            Access to our innovative and simple Internet banking platform and new Business Online (nBOL) for your more complex business banking needs
                            Ability to set up standing orders
                            Set up overdrafts for your business  subject to approval.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Business
