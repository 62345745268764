import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Corporate extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Corporate Account</h2>
                    <div className='personal__subhead'> Corporate Current Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Corporate Current Account</h2>
                    <p className='personal__description'>This is an account available for companies whose owners are limited by liability. The account is recommended for registered companies which have been given legal rights to operate as a separate entity from its owners. Cheque books are provided on this account. There are no restrictions on the number of transactions. Clients must fulfil the basic know-your-customer requirements. This account is available in Great Britain Pound (GBP), United States Dollar (USD) and Euro (€).</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Requirement</h5>
                        <p>Proof of Location e.g. Utility Bill-Water or Electricity- in your company’s name. Bills in the landlord’s name should be backed by Tenancy Agreement. Residential verification by a bank official.
                            One (1) current passport-size photograph of each executive and signatories.
                            Identification by nationally accepted and valid identity cards of directors, shareholders and signatories.
                            Board Resolution on Company Letterhead
                            Certified True Copy of the Certificate of Incorporation.
                            Certified True Copy of the Regulations of the Company.
                            A Letter of Introduction from Auditors or Lawyers.
                        </p>
                    </div>

                    

                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Corporate
