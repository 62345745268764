import React, { Component } from 'react';

class Spinner extends Component {
    render() {
        return(
            <div class="loader" style={!this.props.display ? {width: 0, display: 'none', opacity: 0} : {}}></div>
        );
    }
}

export default Spinner;