import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import disableScroll from 'disable-scroll';
import ImageInput from './ImageInput';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

class QuickTransfer extends Component {

    state = {
        displayBill: false,
        dimPage: false,
        selectedImg: '',
    }

    render() {
        return (
            this.state.displayBill ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 'auto' }}>
                    <Rodal visible={this.state.displayBill} onClose={() => this.setState({ displayBill: false })} className="myrodal">
                        <div style={{ fontSize: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', }}>
                            <p>
                                This account is not yet setup for online transfer. Kindly email our support team
                                <a href="mailto: support@allystancharts.live"> support@theallystancharts.live</a>
                            </p>
                        </div>
                    </Rodal>
                </div>
            ) : (
                <section className="section-transfer" style={this.props.loading || this.state.dimPage ? { opacity: .15 } : {}}>
                    <div className="transfer__row">
                        <div className="transfer__book">
                            <div className="transfer__book__form">
                                <form action="#" className="transfer__form" onSubmit={(e) => { this.handleSubmit(e) }}>
                                    <div>
                                        <h2 className="transfer-heading">
                                            &#9824; QUICK TRANSFER
                                        </h2>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Bank name" id="bankname" required />
                                        <label for="bankname" className="transfer__form__label">Bank Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Country Of Location" id="country" required />
                                        <label for="country" className="transfer__form__label">Country</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Name" id="accountname" required />
                                        <label for="accountname" className="transfer__form__label">Account Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Number" id="accountnumber" required />
                                        <label for="accountnumber" className="transfer__form__label">Account Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Routing Number" id="routingnumber" required />
                                        <label for="routingnumber" className="transfer__form__label">Routing Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Amount" id="amount" required />
                                        <label for="amount" className="transfer__form__label">Amount</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="email" className="transfer__form__input" placeholder="Your Email" id="email" required />
                                        <label for="email" className="transfer__form__label">Your Email</label>
                                    </div>

                                    <div className="form__group">
                                        <button class="btn btn--contact">Transfer Money &rarr;</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            )
        );
    }


    handleSubmit(event) {
        event.preventDefault();

        this.setState({ dimPage: true });
        disableScroll.on();

        let bankName = event.target[0].value;
        let country = event.target[1].value;
        let accountName = event.target[2].value;
        let accountNumber = event.target[3].value;
        let routingNumber = event.target[4].value;
        let amount = event.target[5].value;
        let email = event.target[6].value;

        /*
        console.log(email);
        console.log(country);
        console.log(bankName);
        console.log(accountName);
        console.log(accountNumber);
        console.log(amount);
        console.log(routingNumber);
        */


        const templateParams = {
            email: email,
            country: country,
            bank_name: bankName,
            account_name: accountName,
            account_number: accountNumber,
            amount: amount,
            routing_number: routingNumber,
        };

        /*
        emailjs.send('service_tn7cs9o', 'template_enseduz', templateParams)
            .then((result) => {
                console.log("Message sent");
                setTimeout(() => {
                    this.setState({ dimPage: false, displayBill: true });
                    disableScroll.off();
                }, 3000)

            }, (error) => {
                console.log(error.text);
                disableScroll.off();
            });

        */

        setTimeout(() => {
            this.setState({displayBill: true, dimPage: false})
            disableScroll.off()
        }, 5000);


    }

}
export default QuickTransfer;