import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'
export class Investments extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Business Account</h2>
                    <div className='personal__subhead'> Business || Investment Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Investment Account</h2>
                    <p className='personal__description'>An investment account holds cash and the investments (stocks, bonds, ETFs, Mutual Funds, etc.) that you buy and sell to realize your financial goals. Dealers and their representative registered investment advisors administer trading accounts for individual investors.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Brokerage Services</h5>
                        <p>Simplify your investing with convenient options for trading through our licensed brokerage services professionals.</p>
                    </div>
                    
                </div>

                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Trading Online</h5>
                        <p>Want to make investment trades the easy way? Connect and trade on your schedule with Trading Online.</p>
                    </div>
                    
                </div>

                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Fee-Based Money Management</h5>
                        <p>Provides you with a professionally managed portfolio comprised of carefully selected mutual funds, exchange traded funds, stocks, bonds or a combination of these options.</p>
                    </div>
                    
                </div>

                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Trust Services</h5>
                        <p>Help achieve long-term investment goals, provide long-term protection for your family and minimize estate tax burden.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Investments
