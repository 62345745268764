import React from 'react'
import { useNavigate } from 'react-router-dom'
import Home from './Home';

function HomeHoc(props) {
    const navigate = useNavigate();
    // console.log(navigate)
    return <Home navigate={navigate} {...props.children}/>
    
}

export default HomeHoc;
