import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Premium extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Premium Account</h2>
                    <div className='personal__subhead'> Personal Account || Premium Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Premium Account</h2>
                    <p className='personal__description'>Our Platinum Banking service offers high net-worth individuals a complete suite of personal banking services, complimented by e-banking products, with a view to providing a first class banking experience.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Requirement</h5>
                        <p>Completed account opening form, 1 mandate card [ PDF ], A valid proof of Identity of each signatory (current Driver’s License/ National ID/ International passport/ Student ID card-for students), Proof of address – utility bills for the 3 previous months/ site visitation report/ certificate of residence/ tenancy agreement, 2 passport photographs, Employer’s letter of introduction (for salary account).</p>
                    </div>

                    <div className='personal__body__item'>
                        <h5>Features</h5>
                        <p>Maximum of three (3) withdrawals in a month. (Any additional withdrawal will attract a charge).
                            Cheques on this account can be issued at any International Capital Trust Bank branch as well as any other Bank. The account offers both current and savings account opportunities.
                            Tiered and competitive interest is paid on the account.
                            Opportunity to issue cheques on the account for convenient purposes.
                            Flexible withdrawals
                            Opportunity to issue cheques on the account for convenient purposes.</p>
                    </div>

                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Premium
